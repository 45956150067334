<template>
    <div>
        <v-card id="details" :loading="loading" height="100vh">
            <v-card-title ref="title">
                <v-btn small icon @click="close" color="primary" class="mr-2">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
                <h3 v-if="!request.status && !newRequest">
                    New Purchase Request
                </h3>
                <h3 v-else>
                    Purchase ID: {{ request.number || newRequest.number }}
                </h3>
                <v-btn
                    small
                    icon
                    @click="openRejected"
                    color="red"
                    class="ml-2"
                >
                    <v-icon>
                        mdi-book-cancel-outline
                    </v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-progress-circular
                    indeterminate
                    v-if="saveLoading"
                    color="primary"
                />
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text
                id="virtual-scroll-table"
                class="mt-n5"
                :style="`height:${this.height}px;`"
            >
                <v-form v-model="valid" :disabled="verifyEditableForm()">
                    <v-row no-gutters class="my-4" ref="projectSelector">
                        <v-col cols="12" lg="2" md="2" sm="2" xl="2">
                            <v-select
                                v-model="request.type"
                                :items="types"
                                prepend-icon="mdi-format-list-bulleted-type"
                                hide-details
                                label="Select a Type"
                                :rules="[rules.required]"
                                required
                            >
                            </v-select>
                        </v-col>
                        <v-col
                            cols="12"
                            lg="3"
                            md="3"
                            sm="3"
                            xl="3"
                            class="pl-0 pl-sm-2 pl-md-2 pl-lg-2 pl-xl-2"
                        >
                            <v-autocomplete
                                v-model="request.projectId"
                                :items="projects"
                                item-text="name"
                                item-value="id"
                                prepend-icon="mdi-account-hard-hat"
                                hide-details
                                label="Select a Project"
                                :rules="[rules.required]"
                                required
                                @change="changeProject"
                                :readonly="
                                    [
                                        'purchasing',
                                        'received',
                                        'closed',
                                        'approved',
                                    ].includes(request.status) || !!projectId
                                "
                            />
                        </v-col>
                        <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                            <v-text-field
                                v-model="projectManager.name"
                                hide-details
                                label="Project Manager"
                                prepend-icon="mdi-account"
                                readonly
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                            <v-text-field
                                v-model="selectedProject.sapCode"
                                hide-details
                                label="Project SAP Code"
                                prepend-icon="mdi-barcode"
                                readonly
                            >
                            </v-text-field>
                        </v-col>

                        <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                            <v-text-field
                                v-model="selectedProject.offer"
                                hide-details
                                label="Project Offer"
                                prepend-icon="mdi-file-document"
                                readonly
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters ref="dateSelector">
                        <v-col
                            cols="12"
                            sm="2"
                            md="2"
                            lg="2"
                            xl="2"
                            class="mt-m5 mt-sm-5 mt-md-3 mt-lg-3 mt-xl-3 "
                        >
                            <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                :disabled="
                                    (request.status == 'inTransit' &&
                                        !editPurchasesPermission) ||
                                        request.status == 'received'
                                "
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="request.dueDate"
                                        label="Due Date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[rules.required]"
                                        required
                                        hide-details
                                        class="pt-0 "
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="request.dueDate"
                                    @input="menu = false"
                                    :min="min"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="3"
                            md="3"
                            lg="3"
                            xl="3"
                            v-if="request.buyer"
                            class="pl-0 pl-sm-2 pl-md-2 pl-lg-2 pl-xl-2"
                        >
                            <v-combobox
                                v-model="buyerName"
                                hide-details
                                label="Buyer"
                                prepend-icon="mdi-account-cash"
                                :items="users"
                                item-text="name"
                                :readonly="
                                    request.status &&
                                        request.status != 'inTransit' &&
                                        editPurchasesPermission
                                "
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            sm="3"
                            md="3"
                            lg="3"
                            xl="3"
                            v-if="request.createdBy"
                        >
                            <v-text-field
                                v-model="request.createdBy.name"
                                hide-details
                                label="Requested by"
                                prepend-icon="mdi-account"
                                readonly
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            :cols="colsNumMobile((param = 'deliveryLocation'))"
                            class="pl-0 pl-sm-2 pl-md-2 pl-lg-2 pl-xl-2"
                        >
                            <v-combobox
                                v-model="request.deliveryLocation"
                                :filter="customFilter"
                                :hide-no-data="!search"
                                :items="this.locationOptions"
                                item-value="text"
                                hide-details
                                prepend-icon="mdi-warehouse"
                                :search-input.sync="search"
                                hide-selected
                                label="Delivery Location"
                                required
                                flat
                            />
                        </v-col>

                        <v-col
                            :cols="colsNumMobile((param = 'notes'))"
                            class="pl-0 pl-sm-2 pl-md-2 pl-lg-2 pl-xl-2"
                        >
                            <v-textarea
                                v-model="request.notes"
                                rows="1"
                                hide-details
                                label="Notes"
                                prepend-icon="mdi-text"
                                @blur="updatePurchaseParams"
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-form>
                <div class="pb-1 pt-4">
                    <v-row ref="files">
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                            lg="4"
                            xl="4"
                            class="pb-0 pt-0"
                        >
                            <div class="d-flex my-2">
                                <div class="d-flex align-center">
                                    <strong>ATTACHED: </strong>
                                </div>
                                <div class="d-flex align-center flex-wrap">
                                    <v-btn
                                        color="transparent"
                                        elevation="0"
                                        small
                                        class="ma-1 text-capitalize text--darken-2  pa-2"
                                        v-for="(file, i) in request.attachments"
                                        :key="i"
                                    >
                                        <v-icon
                                            class="mr-1"
                                            color="primary"
                                            style="font-size: 17px;"
                                        >
                                            mdi-paperclip
                                        </v-icon>
                                        <span
                                            @click="
                                                openAttachedFile(
                                                    'attachments',
                                                    file
                                                )
                                            "
                                            style="font-size: 11px; color: #2b81d6; text-decoration: underline; cursor: pointer;"
                                            >{{ file }}</span
                                        >
                                        <v-icon
                                            v-if="
                                                (request.status &&
                                                    request.status !=
                                                        'received' &&
                                                    request.status !=
                                                        'closed' &&
                                                    editPurchasesPermission) ||
                                                    ((!request.status ||
                                                        request.status ==
                                                            'inProgress' ||
                                                        request.status ==
                                                            'onHold' ||
                                                        request.status ==
                                                            'rejected' ||
                                                        request.status ==
                                                            'pricing') &&
                                                        !disableByCreator())
                                            "
                                            @click="
                                                deleteFile('attachments', file)
                                            "
                                            class="ml-1"
                                            small
                                        >
                                            mdi-close-circle
                                        </v-icon>
                                    </v-btn>
                                    <v-btn
                                        @click="onButtonClick('attachments')"
                                        depressed
                                        fab
                                        x-small
                                        color="primary"
                                        class="ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                                        v-if="
                                            ((request.status &&
                                                request.status != 'received' &&
                                                request.status != 'closed' &&
                                                editPurchasesPermission) ||
                                                ((!request.status ||
                                                    request.status ==
                                                        'inProgress' ||
                                                    request.status ==
                                                        'onHold' ||
                                                    request.status ==
                                                        'rejected' ||
                                                    request.status ==
                                                        'pricing') &&
                                                    !disableByCreator())) &&
                                                !assignView
                                        "
                                    >
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                    <input
                                        ref="attachmentUpload"
                                        class="d-none"
                                        type="file"
                                        name="attachments"
                                        @change="onFileChanged"
                                    />
                                </div>
                            </div>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                            lg="4"
                            xl="4"
                            class="pb-0 pt-0"
                        >
                            <div class="d-flex my-2">
                                <div class="d-flex align-center">
                                    <strong>QUOTES: </strong>
                                </div>
                                <div class="d-flex align-center flex-wrap">
                                    <v-btn
                                        color="transparent"
                                        elevation="0"
                                        small
                                        class="ma-1 text-capitalize text--darken-2  pa-2"
                                        v-for="(file, i) in request.quotes"
                                        :key="i"
                                    >
                                        <v-icon
                                            class="mr-1"
                                            color="primary"
                                            style="font-size: 17px;"
                                        >
                                            mdi-paperclip
                                        </v-icon>
                                        <span
                                            @click="
                                                openAttachedFile('quotes', file)
                                            "
                                            style="font-size: 11px; color: #2b81d6; text-decoration: underline; cursor: pointer;"
                                            >{{ file }}</span
                                        >
                                        <v-icon
                                            v-if="
                                                (request.status &&
                                                    request.status !=
                                                        'received' &&
                                                    request.status !=
                                                        'closed' &&
                                                    editPurchasesPermission) ||
                                                    (request.status &&
                                                        (request.status ==
                                                            'rejected' ||
                                                            request.status ==
                                                                'pricing') &&
                                                        !disableByBuyer())
                                            "
                                            @click="deleteFile('quotes', file)"
                                            class="ml-1"
                                            color="grey darken-2"
                                            small
                                        >
                                            mdi-close-circle
                                        </v-icon>
                                    </v-btn>
                                    <v-btn
                                        @click="onButtonClick('quotes')"
                                        depressed
                                        fab
                                        x-small
                                        color="primary"
                                        class="ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                                        v-if="
                                            ((request.status &&
                                                request.status != 'received' &&
                                                request.status != 'closed' &&
                                                editPurchasesPermission) ||
                                                (request.status &&
                                                    (request.status ==
                                                        'rejected' ||
                                                        request.status ==
                                                            'pricing') &&
                                                    !disableByBuyer())) &&
                                                !assignView
                                        "
                                    >
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                    <input
                                        ref="quotesUpload"
                                        class="d-none"
                                        type="file"
                                        name="quotes"
                                        @change="onFileChanged"
                                    />
                                </div>
                            </div>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                            lg="4"
                            xl="4"
                            class="pb-0 pt-0"
                        >
                            <div class="d-flex my-2">
                                <div class="d-flex align-center">
                                    <strong>ORDERS: </strong>
                                </div>
                                <div class="d-flex align-center flex-wrap">
                                    <v-btn
                                        color="transparent"
                                        elevation="0"
                                        small
                                        class="ma-1 text-capitalize text--darken-2  pa-2"
                                        v-for="(file,
                                        i) in request.purchaseOrders"
                                        :key="i"
                                    >
                                        <v-icon
                                            class="mr-1"
                                            color="primary"
                                            style="font-size: 17px;"
                                        >
                                            mdi-paperclip
                                        </v-icon>

                                        <span
                                            @click="
                                                openAttachedFile(
                                                    'purchaseOrders',
                                                    file
                                                )
                                            "
                                            style="font-size: 11px; color: #2b81d6; text-decoration: underline; cursor: pointer;"
                                            >{{ file }}</span
                                        >
                                        <v-icon
                                            v-if="
                                                (request.status &&
                                                    request.status ==
                                                        'purchasing') ||
                                                    (request.status &&
                                                        request.status !=
                                                            'received' &&
                                                        request.status !=
                                                            'closed' &&
                                                        editPurchasesPermission)
                                            "
                                            @click="
                                                deleteFile(
                                                    'purchaseOrders',
                                                    file
                                                )
                                            "
                                            class="ml-1"
                                            small
                                        >
                                            mdi-close-circle
                                        </v-icon>
                                    </v-btn>
                                    <v-btn
                                        @click="onButtonClick('purchaseOrders')"
                                        depressed
                                        fab
                                        x-small
                                        color="primary"
                                        class="ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                                        v-if="
                                            (request.status &&
                                                request.status ==
                                                    'purchasing') ||
                                                (request.status &&
                                                    request.status !=
                                                        'received' &&
                                                    request.status !=
                                                        'closed' &&
                                                    editPurchasesPermission)
                                        "
                                    >
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                    <input
                                        ref="purchaseOrdersUpload"
                                        class="d-none"
                                        type="file"
                                        name="purchaseOrders"
                                        @change="onFileChanged"
                                    />
                                </div>
                            </div>
                        </v-col>
                        <v-col
                            v-if="
                                request.closingFile &&
                                    request.status == 'closed'
                            "
                            cols="12"
                            sm="6"
                            md="4"
                            lg="4"
                            xl="4"
                            class="pb-0 pt-0"
                        >
                            <div class="d-flex my-2">
                                <div class="d-flex align-center">
                                    <strong>CLOSING: </strong>
                                </div>
                                <div class="d-flex align-center flex-wrap">
                                    <v-btn
                                        color="transparent"
                                        elevation="0"
                                        small
                                        class="ma-1 text-capitalize text--darken-2  pa-2"
                                    >
                                        <v-icon
                                            class="mr-1"
                                            color="primary"
                                            style="font-size: 17px;"
                                        >
                                            mdi-paperclip
                                        </v-icon>

                                        <span
                                            @click="
                                                openAttachedFile(
                                                    'closing',
                                                    request.closingFile
                                                )
                                            "
                                            style="font-size: 11px; color: #2b81d6; text-decoration: underline; cursor: pointer;"
                                            >{{ request.closingFile }}</span
                                        >
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
                <v-container fluid px-0>
                    <v-data-table
                        :headers="headers"
                        :items="request.items"
                        class="elevation-0 mt-6"
                        :mobile-breakpoint="0"
                        :loading="loading"
                        disable-pagination
                        hide-default-footer
                        multiple-expand
                        :show-expand="request.status != undefined"
                        :expanded.sync="expanded"
                    >
                        <!--TOP-->
                        <template v-slot:top>
                            <v-row
                                no-gutter
                                class="py-4 mx-0"
                                style="background-color: #eeeeee"
                            >
                                <v-col cols="12" class="d-flex">
                                    <p class="font-weight-medium my-2">
                                        ITEMS
                                    </p>
                                    <v-spacer />
                                    <v-btn
                                        rounded
                                        color="primary"
                                        class="mx-2"
                                        @click="openAddNewItem"
                                        v-if="validateAddNewItem && !assignView"
                                        :disabled="!request.type"
                                    >
                                        <v-icon>
                                            mdi-plus
                                        </v-icon>
                                        {{
                                            $vuetify.breakpoint.smAndDown
                                                ? ''
                                                : 'NEW'
                                        }}
                                    </v-btn>
                                    <v-btn
                                        rounded
                                        color="primary"
                                        class="mx-2"
                                        hide-details
                                        @click.stop="
                                            openAddNewMultipleQuote(
                                                request.items
                                            )
                                        "
                                        v-if="
                                            request.id &&
                                                status[request.status]?.text !=
                                                    'purchasing' &&
                                                status[request.status]?.text !=
                                                    'closed' &&
                                                status[request.status]?.text !=
                                                    'received' &&
                                                contactsByCompany &&
                                                !disableByBuyer()
                                        "
                                    >
                                        REQUEST A QUOTE
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>
                        <!--HEADERS-->
                        <template v-slot:[`header.index`]="{ header }">
                            <p class="my-0" ref="index_column">
                                {{ header.text }}
                            </p>
                        </template>
                        <template v-slot:[`header.code`]="{ header }">
                            <p class="my-0" ref="code_column">
                                {{ header.text }}
                            </p>
                        </template>
                        <template v-slot:[`header.description`]="{ header }">
                            <p class="my-0" ref="item_column">
                                {{ header.text }}
                            </p>
                        </template>
                        <!--ITEMS-->
                        <template v-slot:[`item.index`]="{ index }">
                            <p class="mt-3 font-weight-medium">
                                {{ index + 1 }}
                            </p>
                        </template>
                        <template v-slot:[`item.code`]="{ item }">
                            <p class="mt-3 font-weight-medium">
                                {{ item.code }}
                            </p>
                        </template>
                        <template v-slot:[`item.description`]="{ item }">
                            <p class="mt-3 font-weight-medium">
                                {{ item.description }}
                            </p>
                        </template>
                        <template v-slot:[`item.quantity`]="{ item }">
                            <p :class="validateItemQty(item)">
                                {{ item.quantity }}
                            </p>
                        </template>
                        <template v-slot:[`item.supplier`]="{ item }">
                            <p class="my-0 text-capitalize font-weight-medium">
                                {{ getSupplier(item) }}
                            </p>
                        </template>
                        <template v-slot:[`item.quotes`]="{ item }">
                            <p
                                class="my-0 primary--text font-weight-medium"
                                style="cursor: pointer"
                                @click="openFile(getQuote(item)?.docURL)"
                            >
                                {{ getQuote(item)?.attach }}
                            </p>
                        </template>
                        <template v-slot:[`item.order`]="{ item }">
                            <a
                                v-if="
                                    item.purchaseOrder &&
                                        item.purchaseOrder.file
                                "
                                :href="
                                    item.purchaseOrder.fileURL ||
                                        getOrderURL(item)
                                "
                                target="_blank"
                                >{{ item.purchaseOrder.orderNumber }}</a
                            >
                            <p v-else class="font-weight-medium my-0">
                                {{
                                    item.purchaseOrder
                                        ? item.purchaseOrder.orderNumber
                                        : ''
                                }}
                            </p>
                        </template>
                        <template v-slot:[`item.price`]="{ item }">
                            <p
                                class="font-weight-medium mt-3"
                                v-if="item.quotes"
                            >
                                {{
                                    item.quotes.find(quote => quote.able)
                                        ? numberFormat(
                                              item.quotes.find(
                                                  quote => quote.able
                                              ).price
                                          )
                                        : ''
                                }}
                            </p>
                        </template>
                        <template v-slot:[`item.totalPrice`]="{ item }">
                            <p
                                class="font-weight-medium mt-3"
                                v-if="item.quotes"
                            >
                                {{
                                    item.quotes.find(quote => quote.able)
                                        ? numberFormat(
                                              item.quotes.find(
                                                  quote => quote.able
                                              ).price * item.quantity
                                          )
                                        : ''
                                }}
                            </p>
                        </template>
                        <template v-slot:[`item.image`]="{ item }">
                            <a
                                v-if="item.image"
                                target="_blank"
                                :href="item.image"
                            >
                                view
                            </a>
                        </template>
                        <template v-slot:[`item.action`]="{ item }">
                            <div>
                                <v-btn
                                    small
                                    icon
                                    class="mr-2"
                                    @click="editItem(item)"
                                    v-if="
                                        !request.status ||
                                            request.status == 'inProgress' ||
                                            request.status == 'onHold' ||
                                            request.status == 'preApproval' ||
                                            request.status == 'approval' ||
                                            request.status == 'rejected'
                                    "
                                >
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn
                                    small
                                    icon
                                    class="mr-2"
                                    @click="openOrderForm(item)"
                                    v-if="
                                        status[request.status]?.text ==
                                            'purchasing'
                                    "
                                >
                                    <v-icon>mdi-upload</v-icon>
                                </v-btn>
                                <v-btn
                                    small
                                    icon
                                    class="mr-2"
                                    @click="openDeleteItem(item)"
                                    v-if="
                                        !request.status ||
                                            request.status == 'inProgress' ||
                                            request.status == 'onHold' ||
                                            request.status == 'preApproval' ||
                                            request.status == 'approval' ||
                                            request.status == 'rejected'
                                    "
                                >
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                                <v-btn
                                    small
                                    icon
                                    color="primary"
                                    hide-details
                                    @click.stop="selectFunctionToAdd(item)"
                                    v-if="
                                        request.id &&
                                            status[request.status]?.text !=
                                                'purchasing' &&
                                            status[request.status]?.text !=
                                                'closed' &&
                                            status[request.status]?.text !=
                                                'received' &&
                                            !disableByBuyer() &&
                                            totalQuantities(item)
                                    "
                                >
                                    <v-icon>mdi-plus-circle</v-icon>
                                </v-btn>
                                <v-btn
                                    small
                                    icon
                                    class="mr-2"
                                    @click="openQuotes(item)"
                                    v-if="
                                        status[request.status]?.text ==
                                            'purchasing' ||
                                            status[request.status]?.text ==
                                                'inTransit' ||
                                            status[request.status]?.text ==
                                                'closed' ||
                                            status[request.status]?.text ==
                                                'received'
                                    "
                                >
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                            </div>
                        </template>
                        <!--EXPAND TABLE-->
                        <template
                            v-slot:expanded-item="{ headers, item: material }"
                        >
                            <td
                                :colspan="headers.length"
                                class="py-4 ma-0 pl-10 pr-10 outlined"
                                style="background: white"
                            >
                                <div>
                                    <v-data-table
                                        :headers="quoteHeaders"
                                        :items="
                                            status[request.status]?.text ==
                                                'closed' ||
                                            status[request.status]?.text ==
                                                'inTransit' ||
                                            status[request.status]?.text ==
                                                'received'
                                                ? material.entries
                                                : status[request.status]
                                                      ?.text == 'purchasing'
                                                ? material.quotes.filter(
                                                      quote => quote.able
                                                  )
                                                : material.quotes
                                        "
                                        class="ma-0 pa-0 elevation-0"
                                        :mobile-breakpoint="0"
                                        :loading="quoteLoading"
                                        disable-pagination
                                        hide-default-footer
                                        style="width: auto !important;"
                                    >
                                        <template
                                            v-slot:[`item.date`]="{
                                                item,
                                            }"
                                        >
                                            <p class="my-0">
                                                {{
                                                    typeof item.createdOn ==
                                                    'string'
                                                        ? item.createdOn
                                                        : formatDate(
                                                              item.createdOn
                                                                  .seconds ||
                                                                  item.createdOn
                                                                      ._seconds
                                                          )
                                                }}
                                            </p>
                                        </template>
                                        <template
                                            v-slot:[`item.select`]="{
                                                item,
                                            }"
                                        >
                                            <v-simple-checkbox
                                                color="success"
                                                v-model="item.able"
                                                @click="
                                                    onCheckboxClicked(
                                                        material,
                                                        item
                                                    )
                                                "
                                                :disabled="
                                                    item.able ||
                                                        disableByBuyer()
                                                "
                                            ></v-simple-checkbox>
                                        </template>
                                        <template
                                            v-slot:[`item.receivedBy`]="{
                                                item,
                                            }"
                                        >
                                            <p class="my-0">
                                                {{ item.receivedBy.name }}
                                            </p>
                                        </template>
                                        <template
                                            v-slot:[`item.unitPriceNoTax`]="{
                                                item,
                                            }"
                                        >
                                            <div>
                                                <v-tooltip right>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <div
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            {{
                                                                item.unitPriceNoTax.toFixed(
                                                                    2
                                                                )
                                                            }}
                                                        </div>
                                                    </template>
                                                    <div v-if="item.TRM">
                                                        <p>
                                                            TRM:
                                                            {{
                                                                (
                                                                    1 / item.TRM
                                                                ).toFixed(2)
                                                            }}
                                                        </p>
                                                        <p>
                                                            Value in
                                                            {{ item.currency }}:
                                                            {{
                                                                item.unitPriceNoTaxBeforeTRM
                                                            }}
                                                        </p>
                                                    </div>
                                                </v-tooltip>
                                            </div>
                                        </template>
                                        <template
                                            v-slot:[`item.taxValue`]="{ item }"
                                        >
                                            <div>
                                                <v-tooltip right>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <div
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            {{
                                                                item.taxValue.toFixed(
                                                                    2
                                                                )
                                                            }}
                                                        </div>
                                                    </template>
                                                    <div v-if="item.TRM">
                                                        <p>
                                                            TRM:
                                                            {{
                                                                (
                                                                    1 / item.TRM
                                                                ).toFixed(2)
                                                            }}
                                                        </p>
                                                        <p>
                                                            Value in
                                                            {{ item.currency }}:
                                                            {{
                                                                item.taxValueBeforeTRM
                                                            }}
                                                        </p>
                                                    </div>
                                                </v-tooltip>
                                            </div>
                                        </template>
                                        <template
                                            v-slot:[`item.price`]="{ item }"
                                        >
                                            <div>
                                                <v-tooltip right>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <div
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            {{
                                                                item.price.toFixed(
                                                                    2
                                                                )
                                                            }}
                                                        </div>
                                                    </template>
                                                    <div v-if="item.TRM">
                                                        <p>
                                                            TRM:
                                                            {{
                                                                (
                                                                    1 / item.TRM
                                                                ).toFixed(2)
                                                            }}
                                                        </p>
                                                        <p>
                                                            Value in
                                                            {{ item.currency }}:
                                                            {{
                                                                item.priceBeforeTRM
                                                            }}
                                                        </p>
                                                    </div>
                                                </v-tooltip>
                                            </div>
                                        </template>
                                        <template
                                            v-slot:[`item.supplier`]="{
                                                item,
                                            }"
                                        >
                                            <p class="my-0">
                                                {{ item.supplier.name }}
                                            </p>
                                        </template>
                                        <template
                                            v-slot:[`item.contact`]="{
                                                item,
                                            }"
                                        >
                                            <p class="my-0">
                                                {{ item.contact.name }}
                                            </p>
                                        </template>
                                        <template
                                            v-slot:[`item.file`]="{
                                                item,
                                            }"
                                        >
                                            <v-btn
                                                v-if="item.file"
                                                color="transparent"
                                                elevation="0"
                                                small
                                                class="text-capitalize text--darken-2 "
                                            >
                                                <span
                                                    @click.stop="
                                                        openAttachedFile(
                                                            'quotes',
                                                            item.file[0]
                                                        )
                                                    "
                                                    style="font-size: 11px; color: #2b81d6; text-decoration: underline; cursor: pointer;"
                                                    >{{
                                                        item.file &&
                                                        item.file[0]
                                                            ? item.file[0]
                                                            : ''
                                                    }}</span
                                                >
                                            </v-btn>
                                        </template>
                                        <template
                                            v-slot:[`item.attach`]="{
                                                item,
                                            }"
                                        >
                                            <p
                                                class="my-0 primary--text"
                                                style="cursor: pointer"
                                                @click="openFile(item.docURL)"
                                            >
                                                {{ item.attach }}
                                            </p>
                                        </template>
                                        <template
                                            v-slot:[`item.actions`]="{
                                                item,
                                            }"
                                        >
                                            <div
                                                v-if="
                                                    request.status != 'closed'
                                                "
                                                class="d-flex justify-center my-0"
                                            >
                                                <v-btn
                                                    small
                                                    icon
                                                    class="mr-2"
                                                    @click="
                                                        updateQuote(
                                                            material,
                                                            item
                                                        )
                                                    "
                                                    v-if="
                                                        request.status !=
                                                            'purchasing' &&
                                                            request.status !=
                                                                'inTransit' &&
                                                            request.status !=
                                                                'received' &&
                                                            !disableByBuyer()
                                                    "
                                                >
                                                    <v-icon>
                                                        mdi-pencil
                                                    </v-icon>
                                                </v-btn>
                                                <v-btn
                                                    small
                                                    icon
                                                    class="mr-2"
                                                    @click="
                                                        openDeleteQuote(
                                                            material,
                                                            item
                                                        )
                                                    "
                                                    v-if="
                                                        !item.contacts &&
                                                            status[
                                                                request.status
                                                            ]?.text !=
                                                                'purchasing' &&
                                                            status[
                                                                request.status
                                                            ]?.text !=
                                                                'inTransit' &&
                                                            status[
                                                                request.status
                                                            ]?.text !=
                                                                'received' &&
                                                            !disableByBuyer()
                                                    "
                                                >
                                                    <v-icon>
                                                        mdi-delete
                                                    </v-icon>
                                                </v-btn>
                                                <v-btn
                                                    small
                                                    icon
                                                    class="mr-2"
                                                    @click="
                                                        reSendEmailAlert(item)
                                                    "
                                                    v-if="
                                                        status[request.status]
                                                            ?.text ==
                                                            'pricing' &&
                                                            !disableByBuyer() &&
                                                            !item.price
                                                    "
                                                >
                                                    <v-icon>
                                                        mdi-email-sync
                                                    </v-icon>
                                                </v-btn>
                                                <v-btn
                                                    x-small
                                                    icon
                                                    class="mr-2"
                                                    v-if="
                                                        status[request.status]
                                                            ?.text ==
                                                            'pricing' &&
                                                            !disableByBuyer() &&
                                                            item.requestCounter &&
                                                            item.requestCounter >
                                                                0
                                                    "
                                                    color="primary"
                                                >
                                                    <v-icon>
                                                        mdi-check-all
                                                    </v-icon>
                                                </v-btn>
                                                <div
                                                    v-if="
                                                        status[request.status]
                                                            ?.text ==
                                                            'received' ||
                                                            status[
                                                                request.status
                                                            ]?.text ==
                                                                'inTransit'
                                                    "
                                                    class="d-flex justify-center"
                                                >
                                                    <span class="mt-1"
                                                        >SAP</span
                                                    >
                                                    <v-simple-checkbox
                                                        color="success"
                                                        v-model="item.sap"
                                                        @click="
                                                            validateSAP(
                                                                material,
                                                                item
                                                            )
                                                        "
                                                    ></v-simple-checkbox>
                                                </div>
                                                <div
                                                    v-if="
                                                        (request.status ==
                                                            'received' &&
                                                            editReceivedPurchasePermission) ||
                                                            (request.status ==
                                                                'inTransit' &&
                                                                editPurchasesPermission)
                                                    "
                                                    class="d-flex justify-center"
                                                >
                                                    <v-btn
                                                        small
                                                        icon
                                                        @click="
                                                            editEntry(
                                                                material,
                                                                item
                                                            )
                                                        "
                                                    >
                                                        <v-icon>
                                                            mdi-pencil
                                                        </v-icon>
                                                    </v-btn>
                                                </div>
                                                <div
                                                    v-if="
                                                        ((request.status ==
                                                            'received' &&
                                                            editReceivedPurchasePermission) ||
                                                            (request.status ==
                                                                'inTransit' &&
                                                                editPurchasesPermission)) &&
                                                            deleteEntryPermission
                                                    "
                                                    class="d-flex justify-center"
                                                >
                                                    <v-btn
                                                        small
                                                        icon
                                                        @click="
                                                            openDeleteEntry(
                                                                material,
                                                                item
                                                            )
                                                        "
                                                    >
                                                        <v-icon>
                                                            mdi-delete
                                                        </v-icon>
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </template>
                                    </v-data-table>
                                </div>
                            </td>
                        </template>
                        <template v-slot:footer>
                            <v-simple-table dense class="my-4">
                                <tfoot class="grey lighten-3">
                                    <tr>
                                        <td
                                            class="font-weight-bold text-subtitle-2 text-right "
                                        >
                                            TOTAL
                                            {{
                                                numberFormat(
                                                    request.totalPrice || 0
                                                )
                                            }}
                                        </td>
                                    </tr>
                                </tfoot>
                            </v-simple-table>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card-text>
            <v-card-actions
                ref="actions"
                v-if="
                    this.status[this.request.status]?.text != 'closed' &&
                        !assignView
                "
            >
                <v-btn
                    v-if="
                        request.status == 'received' &&
                            editReceivedPurchasePermission
                    "
                    :color="status['inTransit'].color"
                    rounded
                    @click="openBackToInTransit = true"
                    dark
                >
                    Back to in transit
                </v-btn>
                <v-btn
                    v-if="
                        (request.status &&
                            status[request.status]?.text != 'inProgress' &&
                            status[request.status]?.text != 'onHold' &&
                            status[request.status]?.text != 'rejected' &&
                            status[request.status]?.text != 'inTransit' &&
                            status[request.status]?.text != 'received') ||
                            (finalApprovalPermission &&
                                status[request.status]?.text == 'inTransit') ||
                            (finalApprovalPermission &&
                                status[request.status]?.text == 'purchasing' &&
                                !validateWhetherDeliveriesExist)
                    "
                    color="red"
                    rounded
                    class="mt-n2 ml-3 white--text"
                    @click="openRejectRequest"
                    :disabled="disableReject()"
                >
                    Reject
                </v-btn>
                <v-spacer />
                <v-btn
                    v-if="
                        !(
                            request.status == 'onHold' &&
                            (request.readyForPricing ||
                                request.createdBy.id != userId)
                        ) && validateReceive(request)
                    "
                    :color="status[request.status || 'inProgress']?.color"
                    rounded
                    class="mt-n2 mr-3 white--text"
                    :loading="loading"
                    @click="selectFunction"
                    :disabled="enableButton"
                >
                    {{ selectTextButton() }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <!--NEW ITEM-->
        <v-dialog
            :retain-focus="false"
            persistent
            v-model="openItemForm"
            max-width="400px"
        >
            <AddNewItem
                v-if="openItemForm"
                :request="request"
                :requestId="requestId"
                :item="newItem"
                :originalItem="originalItem"
                :createForm="createItem"
                @addItem="addItem"
                @closeDialog="closeAddNewItem"
                @replaceItem="replaceItem"
            />
        </v-dialog>
        <!--NEW QUOTE-->
        <v-dialog
            :retain-focus="false"
            persistent
            v-model="openQuoteForm"
            max-width="500px"
        >
            <AddNewQuote
                v-if="openQuoteForm"
                :quote="quote"
                :originalQuote="originalQuote"
                :createForm="createQuoteForm"
                :purchaseId="request.id"
                :itemId="selectedItem.id"
                :item="selectedItem"
                :contactsByCompany="contactsByCompany"
                @addQuote="addQuote"
                @replaceQuote="replaceQuote"
                @closeDialog="closeAddNewQuote"
            />
        </v-dialog>

        <!--NEW MULTIPLE QUOTES-->
        <v-dialog
            :retain-focus="false"
            persistent
            v-model="openMultipleQuoteForm"
            max-width="500px"
        >
            <AddNewMultipleQuotes
                v-if="openMultipleQuoteForm"
                :quote="quote"
                :originalQuote="originalQuote"
                :createForm="createQuoteForm"
                :purchaseId="request.id"
                :items="selectedItems"
                :contactsByCompany="contactsByCompany"
                @addQuote="addMultipleQuotes"
                @replaceQuote="replaceQuote"
                @closeDialog="closeAddNewMultipleQuote"
            />
        </v-dialog>
        <!--ADD NEW ENTRY-->
        <v-dialog
            :retain-focus="false"
            persistent
            v-model="newEntryForm"
            max-width="400px"
        >
            <AddNewEntry
                v-if="newEntryForm"
                :request="request"
                :item="selectedItem"
                :permissions="permissions"
                :entry="entry"
                :originalEntry="originalEntry"
                :createForm="entryCreateForm"
                :serviceUser="
                    request.createdBy.id == userId && request.type == 'service'
                "
                @closeDialog="closeAddNewEntry"
                @addEntry="addNewEntry"
                @replaceEntry="replaceEntry"
            />
        </v-dialog>
        <!--Error Alert-->
        <Errors />
        <!--Delete Entry-->
        <v-dialog
            v-model="entryDeleteWarning"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <DeleteEntry
                v-if="entryDeleteWarning"
                :selectedItem="selectedItem"
                :entry="entry"
                :purchaseId="request.id"
                @close="closeDeleteEntry"
                @remove="removeEntry"
            />
        </v-dialog>
        <!--Delete Quote-->
        <v-dialog
            v-model="deleteWarning"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="deleteWarning">
                <v-card-title class="text-h5">Delete Quote</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this quote?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeDeleteQuote">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteQuote"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Delete Item-->
        <v-dialog
            v-model="deleteItemWarning"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="deleteItemWarning">
                <v-card-title class="text-h5">Delete Item</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this item?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeDeleteItem">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteItem"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Purchase Order-->
        <v-dialog
            v-model="orderPurchase"
            persistent
            max-width="600px"
            :retain-focus="false"
        >
            <PurchaseOrder
                v-if="orderPurchase"
                scope="item"
                :item="selectedItem"
                :request="request"
                @replaceItem="replaceItem"
                @closeDialog="closeOrderForm"
            />
        </v-dialog>
        <!--Reject Request-->
        <v-dialog
            v-model="rejectRequestForm"
            persistent
            max-width="600px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="rejectRequestForm">
                <v-card-title class="text-h5">Reject</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            Write the reasons why you decide to reject this
                            requisition
                        </v-col>
                    </v-row>
                    <v-form v-model="validRejectForm">
                        <v-row no-gutters class="mb-2 mt-n5">
                            <v-col cols="12">
                                <v-textarea
                                    v-model="rejectedNotes"
                                    rows="2"
                                    hide-details
                                    label="Notes"
                                    prepend-icon="mdi-text"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeRejectRequest">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="rejectRequest"
                        text
                        color="error"
                        :loading="loading"
                        :disabled="!rejectedNotes"
                    >
                        Reject
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Rejected-->
        <v-dialog persistent max-width="800px" v-model="rejectionTable">
            <RejectionTable
                v-if="rejectionTable"
                :items="request.rejectionHistory"
                @closeDialog="closeRejected"
            />
        </v-dialog>
        <!--Quantity Warning Message-->
        <v-dialog
            v-model="quantityWarningMessage"
            persistent
            max-width="600px"
            :retain-focus="false"
        >
            <v-card
                :loading="loading"
                v-if="quantityWarningMessage"
                class="py-2"
            >
                <v-card-title class="text-h5">Warning</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            There are items without completing the quantity of
                            deliveries, are you sure you want to continue with
                            the operation ?
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="red"
                        @click="quantityWarningMessage = false"
                    >
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="sendToReceived"
                        color="primary"
                        :loading="loading"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Quotes Dialog-->
        <v-dialog persistent max-width="900px" v-model="viewQuotes">
            <ItemQuotes
                v-if="viewQuotes"
                :item="selectedItem"
                @close="closeQuotes"
            />
        </v-dialog>
        <!--Back to in transit-->
        <v-dialog
            v-model="openBackToInTransit"
            persistent
            max-width="600px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="openBackToInTransit">
                <v-card-title class="text-h5">Back to in transit</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            Write the reasons why you decide to back to in
                            transit
                        </v-col>
                    </v-row>
                    <v-form>
                        <v-row no-gutters class="mb-2 mt-n5">
                            <v-col cols="12">
                                <v-textarea
                                    v-model="backToInTransitNotes"
                                    rows="2"
                                    hide-details
                                    label="Notes"
                                    prepend-icon="mdi-text"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="openBackToInTransit = false"
                    >
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="backToIntransit"
                        text
                        color="error"
                        :loading="loading"
                        :disabled="!backToInTransitNotes"
                    >
                        Confirm
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Close Request-->
        <v-dialog
            v-model="closeRequestForm"
            persistent
            max-width="600px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="closeRequestForm">
                <v-card-title class="text-h5">Close purchase</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            You must attach a file before closing your purchase.
                        </v-col>
                    </v-row>
                    <v-form>
                        <v-row no-gutters class="my-2">
                            <v-col cols="12">
                                <v-file-input
                                    prefix="Upload file"
                                    v-model="request.closingFile"
                                    required
                                    prepend-icon="mdi-upload"
                                    style="color:blue;"
                                ></v-file-input>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="closeRequestFormDialog"
                    >
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="closePurchase"
                        text
                        color="error"
                        :loading="loading"
                        :disabled="!request.closingFile"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Confirmation alert -->
        <v-dialog
            v-model="sendWarning"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="sendWarning">
                <v-card-title class="text-h5">Send Email</v-card-title>
                <v-card-text>
                    Are you sure you want to send this email?
                </v-card-text>

                <v-card-actions>
                    <v-btn text color="error" @click="closeAlert">
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="reSendEmail(selectedQuote)"
                        text
                        color="primary"
                        :loading="loading"
                    >
                        Send
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import AddNewItem from './AddNewItem.vue'
import AddNewQuote from './AddNewQuote.vue'
import AddNewMultipleQuotes from './AddNewMultipleQuotes.vue'
import AddNewEntry from './AddNewEntry.vue'
import API from '@/services/api'
import { mapMutations } from 'vuex'
import moment from 'moment'
import { objDiff } from '@/helpers/objDiff.js'
import _ from 'lodash'
import Errors from '@/components/Layout/Errors'
import { storage } from '@/services/firebase'
import PurchaseOrder from '@/components/Purchasing/PurchaseOrder.vue'
import RejectionTable from '@/components/Purchasing/RejectionTable.vue'
import { loadImage } from '../../helpers/imageHandler'
import ItemQuotes from '@/components/Purchasing/ItemQuotes.vue'
import { deleteFile } from '@/services/storage/deleteFile.js'
import { openFile } from '@/services/storage/openFile.js'
import { saveFile } from '@/services/storage/saveFile.js'
import DeleteEntry from '@/components/Purchasing/DeleteEntry'

export default {
    name: 'PurchaseRequest',
    props: {
        createForm: {
            type: Boolean,
            required: true,
            default: () => false,
        },
        request: {
            type: Object,
            default: () => ({
                type: 'material',
                items: [],
            }),
        },
        originalRequest: {
            type: Object,
            default: () => undefined,
        },
        projectId: {
            type: String,
            default: () => undefined,
        },
    },
    components: {
        AddNewItem,
        AddNewQuote,
        AddNewMultipleQuotes,
        AddNewEntry,
        Errors,
        PurchaseOrder,
        RejectionTable,
        ItemQuotes,
        DeleteEntry,
    },
    data: () => ({
        closeRequestForm: false,
        users: [],
        backToInTransitNotes: '',
        openBackToInTransit: false,
        editReceivedPurchasePermission: false,
        editPurchasesPermission: false,
        entryDeleteWarning: false,
        quantityWarningMessage: false,
        userId: '',
        rejectionTable: false,
        validRejectForm: false,
        rejectedNotes: undefined,
        rejectRequestForm: false,
        orderPurchase: false,
        serviceUser: false,
        types: ['material', 'asset', 'service'],
        min: new Date().toISOString(),
        companyId: JSON.parse(localStorage.getItem('company')),
        createQuoteForm: true,
        projectManager: {},
        createItem: true,
        newEntryForm: false,
        expanded: [],
        loading: false,
        selectedProject: { sapCode: '', offer: '' },
        headers: [
            {
                text: 'INDEX',
                value: 'index',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CODE',
                value: 'code',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ITEM',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'quantity',
                align: 'center',
                sortable: false,
                width: '95',
            },
            {
                text: 'Price',
                value: 'price',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Total',
                value: 'totalPrice',
                align: 'center',
                sortable: false,
            },
            {
                text: 'SUPPLIER',
                value: 'supplier',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CONTACT',
                value: 'contact',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QUOTE',
                value: 'quotes',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'left',
                sortable: false,
            },
            {
                text: 'ORDER',
                value: 'order',
                align: 'center',
                sortable: false,
            },
            {
                text: 'IMAGE',
                value: 'image',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTION',
                value: 'action',
                align: 'center',
                sortable: false,
            },
        ],
        quoteHeaders: [
            {
                text: 'Quotes',
                value: 'select',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ID',
                value: 'id',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Supplier',
                value: 'supplier',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Quote',
                value: 'file',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Days',
                value: 'days',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Quoted Qty',
                value: 'quotedQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Unit Price Before Tax',
                value: 'unitPriceNoTax',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Tax Value',
                value: 'taxValue',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Total Unit Price',
                value: 'price',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Notes',
                value: 'notes',
                align: 'left',
                sortable: false,
            },
            {
                text: 'Bidder General Notes',
                value: 'bidderGeneralNotes',
                align: 'left',
                sortable: false,
            },
            {
                text: 'Bidder Item Notes',
                value: 'bidderItemNotes',
                align: 'left',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        projects: [],
        permissions: [],
        height: 0,
        menu: false,
        date: undefined,
        notes: undefined,
        openItemForm: false,
        newItem: {},
        openQuoteForm: false,
        quote: {},
        originalQuote: {},
        selectedItem: {},
        quoteLoading: false,
        status: Object.freeze({
            inProgress: {
                text: 'inProgress',
                color: 'primary',
            },
            onHold: {
                text: 'onHold',
                color: 'green',
            },
            pricing: {
                text: 'pricing',
                color: '#3293e6',
            },
            preApproval: {
                text: 'preApproval',
                color: '#FF9800',
            },
            approval: {
                text: 'approval',
                color: '#27BC4F',
            },
            purchasing: {
                text: 'purchasing',
                color: 'primary',
            },
            inTransit: {
                text: 'inTransit',
                color: '#FF9800',
            },
            received: {
                text: 'received',
                color: '#FF9800',
            },
            closed: {
                text: 'closed',
                color: '',
            },
            rejected: {
                text: 'rejected',
                color: '#FF9800',
            },
        }),
        originalItem: {},
        deleteWarning: false,
        deleteItemWarning: false,
        entry: {},
        originalEntry: {},
        entryCreateForm: true,
        rules: {
            required: v => !!v || 'The value is required',
            number: v => v > 0 || 'The value is invalid',
        },
        valid: false,
        buyerName: null,
        totalEntries: 0,
        finalApprovalPermission: false,
        inTransitPermission: false,
        viewQuotes: false,
        saveLoading: false,
        search: null,
        locationOptions: [
            { text: 'Almacén Miami' },
            { text: 'Almacén Es Metals' },
            { text: 'Solecarga' },
            { text: 'Almacén Vía 40' },
        ],
        newRequest: undefined,
        requestOrders: false,
        requestQuotes: false,
        localAttachments: {},
        assignView: false,
        receivePermission: false,
        deleteEntryPermission: false,
        requestId: undefined,
        contacts: [],
        contactsByCompany: null,
        openMultipleQuoteForm: false,
        selectedItems: {},
        selectedQuote: [],
        sendWarning: false,
    }),
    computed: {
        validateWhetherDeliveriesExist() {
            for (const item of this.request.items || []) {
                if (item.entries && item.entries.length > 0) {
                    return true
                }
            }
            return false
        },
        requestDiff() {
            if (!this.createForm) {
                return objDiff(this.originalRequest, this.request)
            } else {
                return null
            }
        },
        enableButton() {
            if (
                this.request.status &&
                this.status[this.request.status]?.text == 'pricing'
            ) {
                return (
                    this.validateQuotes() ||
                    !this.requestQuotes ||
                    this.disableByBuyer()
                )
            } else if (
                (this.request.status &&
                    this.status[this.request.status]?.text == 'approval') ||
                this.status[this.request.status]?.text == 'preApproval'
            ) {
                return this.validateQuotes() || !this.requestQuotes
            } else if (
                this.request.status &&
                this.status[this.request.status]?.text == 'received'
            ) {
                return this.validateEntries()
            } else if (
                this.request.status &&
                this.status[this.request.status]?.text == 'purchasing'
            ) {
                return this.validatePurchaseOrder() || !this.requestOrders
            } else if (
                this.request.status == 'rejected' &&
                !this.request.buyer
            ) {
                return true
            } else if (this.request.status == 'inTransit') {
                const requestEntries = this.validateTotalEntries()
                if (requestEntries > 0) {
                    return false
                } else {
                    return true
                }
            } else if (!this.request.status) {
                return !(
                    this.request.type &&
                    this.request.projectId &&
                    this.request.dueDate &&
                    this.request.deliveryLocation
                )
            }
            return false
        },
        validateAddNewItem() {
            return (
                (this.request.createdBy
                    ? this.request.createdBy.id == this.userId ||
                      this.editPurchasesPermission
                    : true) &&
                this.request.status != 'pricing' &&
                this.request.status != 'purchasing' &&
                this.request.status != 'inTransit' &&
                this.request.status != 'closed' &&
                this.request.status != 'received' &&
                this.request.status != 'preApproval' &&
                this.request.status != 'approval'
            )
        },
    },
    watch: {
        'request.projectId': async function() {
            try {
                this.loading = true
                this.setProjectManager()
                if (this.request.id) {
                    await this.updatePurchaseParams()
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async buyerName() {
            if (this.buyerName) {
                this.request.buyer = this.buyerName.id
            }
            if (this.request.id) {
                await this.updatePurchaseParams()
            }
        },
        'request.deliveryLocation': async function() {
            if (this.request.id) {
                await this.updatePurchaseParams()
            }
        },
        'request.dueDate': async function() {
            if (this.request.id) {
                await this.updatePurchaseParams()
            }
        },
        'request.type': async function() {
            if (this.request.id) {
                await this.updatePurchaseParams()
            }
        },
    },
    updated() {
        this.onResize()
    },
    async mounted() {
        try {
            this.loading = true
            // retrieve lite users
            await this.getUsers()
            // validate request
            this.assignView =
                this.$router.currentRoute.name == 'purchasing-assign'
            if (this.request) this.calculateTotal()
            // get user
            const {
                data: { user },
            } = await API.getMyInfo()
            this.userId = user.id
            this.permissions = user.permissions
            // retrieve permissions
            this.finalApprovalPermission = user.permissions.includes(
                'finalApproval'
            )
            this.inTransitPermission = user.permissions.includes(
                'inTransitPurchase'
            )
            this.receivePermission = user.permissions.includes(
                'receivedPurchase'
            )
            this.deleteEntryPermission = user.permissions.includes(
                'deleteEntry'
            )
            this.editPurchasesPermission = user.permissions.includes(
                'editPurchases'
            )
            this.editReceivedPurchasePermission = user.permissions.includes(
                'editReceivedPurchase'
            )
            // filter headers for status
            if (
                this.request.status != undefined &&
                (this.status[this.request.status].text == 'preApproval' ||
                    this.status[this.request.status].text == 'approval')
            ) {
                this.headers = this.headers.filter(
                    header => header.value !== 'action'
                )
                this.quoteHeaders = this.quoteHeaders.filter(
                    header => header.value !== 'actions'
                )
            } else if (
                this.request.status == undefined ||
                this.status[this.request.status].text == 'inProgress' ||
                this.status[this.request.status].text == 'onHold' ||
                this.request.status == 'rejected' ||
                this.status[this.request.status].text == 'pricing'
            ) {
                this.headers = this.headers.filter(
                    header =>
                        header.value !== 'supplier' &&
                        header.value !== 'contact' &&
                        header.value !== 'quotes' &&
                        header.text.toLowerCase() !== 'order'
                )
            } else if (
                this.request.status != undefined &&
                this.status[this.request.status].text == 'purchasing'
            ) {
                this.headers = this.headers.filter(
                    header =>
                        header.value !== 'supplier' &&
                        header.value !== 'contact' &&
                        header.value !== 'quotes'
                )
                this.quoteHeaders.splice(0, 1)
                this.quoteHeaders.splice(this.quoteHeaders.length - 1, 1)
            } else if (
                this.request.status != undefined &&
                (this.status[this.request.status].text == 'inTransit' ||
                    this.status[this.request.status].text == 'received' ||
                    this.status[this.request.status].text == 'closed')
            ) {
                const width =
                    this.$refs.index_column.clientWidth +
                    this.$refs.code_column.clientWidth +
                    this.$refs.item_column.clientWidth +
                    16 * 7
                this.quoteHeaders = [
                    {
                        text: 'Received By',
                        value: 'receivedBy',
                        align: 'left',
                        sortable: false,
                        width: `${width / 2}`,
                    },
                    {
                        text: 'Date',
                        value: 'date',
                        align: 'center',
                        sortable: false,
                        width: `${width / 2}`,
                    },
                    {
                        text: 'QTY',
                        value: 'quantity',
                        align: 'center',
                        sortable: false,
                        width: '95',
                    },
                    {
                        text: 'Notes',
                        value: 'notes',
                        align: 'left',
                        sortable: false,
                    },
                ]
                if (this.request.status != 'closed') {
                    this.quoteHeaders.push({
                        text: 'Actions',
                        value: 'actions',
                        align: 'center',
                        sortable: false,
                    })
                }
            } else {
                this.headers = this.headers.filter(
                    header => header.text.toLowerCase() !== 'order'
                )
            }

            if (this.assignView) {
                this.headers = this.headers.filter(
                    header => header.value !== 'action'
                )
                this.quoteHeaders = this.quoteHeaders.filter(
                    header => header.value !== 'actions'
                )
            }

            this.expanded = ['preApproval', 'approval'].includes(
                this.request.status
            )
                ? []
                : this.request.items

            // set project
            const genericProjects = await API.getGenericProjects()
            const liteProjects = await API.getLiteProjectsByUser()
            let mergedProjects = [...genericProjects]

            for (const liteProject of liteProjects) {
                if (
                    !genericProjects.some(
                        genericProject => genericProject.id === liteProject.id
                    )
                ) {
                    mergedProjects.push(liteProject)
                }
            }

            this.projects = mergedProjects

            if (
                this.request.project &&
                (this.projects == 0 ||
                    !this.projects.find(
                        project => project.id == this.request.project.id
                    ))
            ) {
                this.projects.push(this.request.project)
            }
            this.request.projectId = this.request.project?.id
            if (this.projectId) {
                this.request.projectId = this.projectId
            }
            this.setProjectManager()
            if (this.request.projectId) {
                this.selectedProject = this.projects.find(
                    p => p.id == this.request.projectId
                )
            }
            // set buyer and load items images
            if (this.request.id) {
                if (this.request.buyer) {
                    this.buyerName = await this.getBuyer(this.request.buyer)
                }
                for (const item of this.request.items
                    ? this.request.items
                    : []) {
                    if (item.picture) {
                        item.image = await loadImage(
                            `${this.companyId}/purchases/${this.request.id}/images`,
                            item.picture
                        )
                    }
                }
                this.request.items = [...this.request.items]
            }

            // load quotes documents
            await this.loadDocuments()
            this.requestQuotes =
                this.request.quotes && this.request.quotes.length > 0

            this.requestOrders =
                this.request.purchaseOrders &&
                this.request.purchaseOrders.length > 0

            this.loadContacts()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async loadContacts() {
            try {
                const {
                    data: { contacts },
                } = await API.getContacts()

                this.contacts = contacts
                this.contacts = this.contacts.filter(
                    contact => contact.supplierId
                )
                this.contactsByCompany = this.contacts.reduce(
                    (acc, current) => {
                        // Si aún no existe la clave supplierId en el acumulador, la creamos como un array vacío
                        if (!acc[current.supplierId]) {
                            acc[current.supplierId] = []
                        }
                        // Agregamos el objeto actual al array correspondiente a la clave supplierId
                        acc[current.supplierId].push(current)

                        return acc
                    },
                    {}
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async openRequestFormDialog() {
            try {
                let thereIsAnIncompleteAmount = false
                for (const item of this.request.items) {
                    if (!item.entries || item.entries?.length == 0) {
                        thereIsAnIncompleteAmount = true
                        break
                    }
                    const totalEntries = item.entries.reduce(
                        (accumulator, entry) => entry.quantity + accumulator,
                        0
                    )
                    if (item.quantity > totalEntries) {
                        thereIsAnIncompleteAmount = true
                        break
                    }
                }
                if (thereIsAnIncompleteAmount) {
                    this.closeRequestForm = true
                } else {
                    await this.closePurchase()
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        closeRequestFormDialog() {
            this.request.closingFile = undefined
            this.closeRequestForm = false
        },
        validateItemQty(item) {
            if (this.request.status == 'inTransit') {
                let entryQty = 0
                if (item.entries && item.entries.length > 0) {
                    entryQty = item.entries.reduce(
                        (accumulator, entry) => entry.quantity + accumulator,
                        0
                    )
                }
                if (entryQty >= item.quantity) {
                    return 'mt-3 font-weight-medium green--text'
                } else if (entryQty == 0) {
                    return 'mt-3 font-weight-medium red--text'
                } else {
                    return 'mt-3 font-weight-medium orange--text'
                }
            }
            return 'mt-3 font-weight-medium'
        },
        async getUsers() {
            try {
                const {
                    data: { users },
                } = await API.getLiteUsers()
                this.users = users
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async backToIntransit() {
            try {
                this.loading = true
                await API.backToIntransit({
                    id: this.request.id,
                    notificationId: 'pFDh25EcR74EMiuypq8M',
                    backToInTransitNotes: this.backToInTransitNotes,
                })
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        removeEntry() {
            try {
                const itemIndex = this.request.items.findIndex(
                    item => item.id == this.selectedItem.id
                )
                if (itemIndex > -1) {
                    this.request.items[itemIndex].entries = this.request.items[
                        itemIndex
                    ].entries.filter(entry => entry.id != this.entry.id)
                }
                this.closeDeleteEntry()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        colsNumMobile(param) {
            if (param == 'deliveryLocation') {
                if (this.$vuetify.breakpoint.xs) {
                    return '12'
                } else {
                    if (this.request.createdBy) {
                        if (this.request.buyer) {
                            return '2'
                        } else {
                            return '3'
                        }
                    } else {
                        return '3'
                    }
                }
            } else if (param == 'notes') {
                if (this.$vuetify.breakpoint.xs) {
                    return '12'
                } else {
                    if (this.request.createdBy) {
                        if (this.request.buyer) {
                            return '2'
                        } else {
                            return '4'
                        }
                    } else {
                        return '5'
                    }
                }
            }
        },
        openDeleteEntry(material, entry) {
            this.selectedItem = _.cloneDeep(material)
            this.entry = _.cloneDeep(entry)
            this.entryDeleteWarning = true
        },
        closeDeleteEntry() {
            this.selectedItem = {}
            this.entry = {}
            this.entryDeleteWarning = false
        },
        onButtonClick(type) {
            if (type == 'attachments') {
                this.$refs.attachmentUpload.click()
            } else if (type == 'quotes') {
                this.$refs.quotesUpload.click()
            } else if (type == 'purchaseOrders') {
                this.$refs.purchaseOrdersUpload.click()
            }
        },
        async onFileChanged(e) {
            if (e.target.files.length > 0) {
                const file = e.target.files[0]
                if (e.target.name == 'attachments' && !this.request.id) {
                    this.localAttachments[file.name] = {
                        url: URL.createObjectURL(file),
                        file,
                    }
                    this.request = {
                        ...this.request,
                        attachments: [
                            ...(this.request[e.target.name]
                                ? this.request[e.target.name]
                                : []),
                            file.name,
                        ],
                    }
                } else {
                    await this.saveFile(e.target.name, file)
                }

                switch (e.target.name) {
                    case 'purchaseOrders':
                        this.$refs.purchaseOrdersUpload.value = ''
                        break
                    case 'quotes':
                        this.$refs.quotesUpload.value = ''
                        break
                    case 'attachments':
                        this.$refs.attachmentUpload.value = ''
                        break
                    default:
                        return null
                }
            }
        },
        async deleteFile(type, file) {
            try {
                this.loading = true
                if (type == 'attachments' && !this.request.id) {
                    delete this.localAttachments[file]
                } else {
                    await API.updatePurchaseFiles(this.request.id, {
                        [type]: this.request[type].filter(f => f != file),
                    })
                    await deleteFile(
                        file,
                        `${this.companyId}/purchases/${this.request.id}/${type}`
                    )
                }
                this.request[type] = this.request[type].filter(f => f != file)
                this.requestQuotes =
                    this.request.quotes && this.request.quotes.length > 0
                this.requestOrders =
                    this.request.purchaseOrders &&
                    this.request.purchaseOrders.length > 0
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async saveFile(type, file) {
            try {
                this.loading = true
                await API.updatePurchaseFiles(this.request.id, {
                    [type]: this.request[type]
                        ? [...this.request[type], file.name]
                        : [file.name],
                })
                await saveFile(
                    file,
                    `${this.companyId}/purchases/${this.request.id}/${type}`
                )
                if (this.request[type]) {
                    this.request[type].push(file.name)
                } else {
                    this.request[type] = [file.name]
                }
                this.requestQuotes =
                    this.request.quotes && this.request.quotes.length > 0

                this.requestOrders =
                    this.request.purchaseOrders &&
                    this.request.purchaseOrders.length > 0
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async openAttachedFile(type, file) {
            try {
                this.loading = true
                if (type == 'attachments' && !this.request.id) {
                    window.open(this.localAttachments[file].url, '_blank')
                } else {
                    await openFile(
                        file,
                        `${this.companyId}/purchases/${this.request.id}/${type}`
                    )
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeQuotes() {
            this.viewQuotes = false
            this.selectedItem = {}
        },
        openQuotes(item) {
            this.selectedItem = _.cloneDeep(item)
            this.selectedItem.quotes.forEach(quote => {
                quote.dueDate = this.selectedItem.purchaseOrder.dueDate
            })
            this.viewQuotes = true
        },
        openFile(url) {
            window.open(url, '_blank')
        },
        closeRejected() {
            this.rejectionTable = false
        },
        openRejected() {
            this.rejectionTable = true
        },
        async getOrderURL(item) {
            const path = `${this.companyId}/purchases/${this.request.id}/purchase_order`
            const storageRef = storage().ref(path)
            const docRef = storageRef.child(item.purchaseOrder.file)
            await docRef
                .getDownloadURL()
                .then(url => {
                    item.purchaseOrder.fileURL = url
                })
                .catch(() => {
                    item.purchaseOrder.fileURL
                })
            return item.purchaseOrder.fileURL
        },
        openOrderForm(item) {
            this.selectedItem = { ...item }
            this.orderPurchase = true
        },
        closeOrderForm() {
            this.orderPurchase = false
            this.selectedItem = {}
        },
        async getBuyer(buyer) {
            try {
                return await API.getUser(buyer)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async changeProject() {
            try {
                this.loading = true
                this.setProjectManager()
                if (this.request.id) {
                    await this.updatePurchaseParams()
                }
                this.selectedProject = this.projects.find(
                    p => p.id == this.request.projectId
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openRejectRequest() {
            this.rejectRequestForm = true
        },
        closeRejectRequest() {
            this.rejectRequestForm = false
        },
        async rejectRequest() {
            try {
                this.loading = true
                await API.rejectPurchaseRequest(this.request.id, {
                    status: 'rejected',
                    notificationId: 'JQqZl0uGjBJCk450YrZ3',
                    rejectedNotes: this.rejectedNotes,
                })
                this.$emit('rejectRequest')
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.rejectedNotes = undefined
            }
        },
        async validateSAP(item, entry) {
            try {
                this.loading = true
                await API.updateEntryToRequestsItem({
                    requestId: this.request.id,
                    itemId: item.id,
                    entryId: entry.id,
                    sap: entry.sap,
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        selectFunctionToAdd(item) {
            if (this.status[this.request.status].text == 'inTransit') {
                this.openAddNewEntry(item)
            } else {
                this.openAddNewQuote(item)
            }
        },
        getQuote(item) {
            const selectedQuote = item.quotes?.find(quote => quote.able)
            return selectedQuote
        },
        getSupplier(item) {
            const selectedQuote = item.quotes?.find(quote => quote.able)
            return selectedQuote ? selectedQuote.supplier.name : undefined
        },
        getContact(item) {
            const selectedQuote = item.quotes?.find(quote => quote.able)
            return selectedQuote ? selectedQuote.contact.name : undefined
        },
        async loadDocuments() {
            try {
                if (this.request.items)
                    for (const item of this.request.items) {
                        if (item.quotes) {
                            for (const quote of item.quotes) {
                                if (quote.attach) {
                                    const path = `${this.companyId}/purchases/${this.request.id}/items/${item.id}/quotes`
                                    const storageRef = storage().ref(path)
                                    const docRef = storageRef.child(
                                        quote.attach
                                    )
                                    docRef
                                        .getDownloadURL()
                                        .then(url => {
                                            quote.docURL = url
                                        })
                                        .catch(() => {
                                            document.docURL = ''
                                        })
                                }
                            }
                        }
                    }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        replaceQuote(itemId, quote) {
            const index = this.request.items.findIndex(
                item => item.id == itemId
            )
            if (index >= 0) {
                const quoteIndex = this.request.items[index].quotes.findIndex(
                    q => q.id == quote.id
                )
                if (quoteIndex >= 0) {
                    this.request.items[index].quotes.splice(quoteIndex, 1, {
                        ...this.request.items[index].quotes[quoteIndex],
                        ...quote,
                    })
                    this.request.items.splice(index, 1, {
                        ...this.request.items[index],
                        quotes: [...this.request.items[index].quotes],
                    })
                }
                this.$emit('replaceRequest', {
                    id: this.request.id,
                    items: this.request.items,
                })
            }
            this.calculateTotal()
        },
        updateQuote(item, quote) {
            this.openQuoteForm = true
            this.createQuoteForm = false
            this.selectedItem = _.cloneDeep(item)
            this.quote = _.cloneDeep(quote)
            this.originalQuote = _.cloneDeep(quote)
            this.calculateTotal()
        },
        addQuote(itemId, quote) {
            const index = this.request.items.findIndex(
                item => item.id == itemId
            )
            if (index >= 0) {
                if (this.request.items[index].quotes != undefined) {
                    this.request.items.splice(index, 1, {
                        ...this.request.items[index],
                        quotes: [...this.request.items[index].quotes, quote],
                    })
                } else {
                    this.request.items.splice(index, 1, {
                        ...this.request.items[index],
                        quotes: [quote],
                    })
                }
                this.$emit('replaceRequest', {
                    id: this.request.id,
                    items: this.request.items,
                })
            }
            this.calculateTotal()
        },

        addMultipleQuotes(params) {
            this.request.items = params.items
            this.$emit('replaceRequest', {
                id: this.request.id,
                items: this.request.items,
            })
        },
        async setProjectManager() {
            const index = this.projects.findIndex(
                project => project.id == this.request.projectId
            )
            if (index >= 0) {
                if (this.projects[index].projectManager) {
                    const user = await API.getUser(
                        this.projects[index].projectManager
                    )
                    this.projectManager = {
                        id: user.id,
                        name: user.name,
                    }
                } else {
                    this.projectManager = this.request.projectManager
                }
            }
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('YYYY-MM-DD HH:mm')
        },
        addNewEntry(entry) {
            const index = this.request.items.findIndex(
                item => item.id == this.selectedItem.id
            )
            if (index >= 0) {
                if (this.request.items[index].entries != undefined) {
                    this.request.items.splice(index, 1, {
                        ...this.request.items[index],
                        entries: [...this.request.items[index].entries, entry],
                    })
                } else {
                    this.request.items.splice(index, 1, {
                        ...this.request.items[index],
                        entries: [entry],
                    })
                }
            }
        },
        editEntry(item, entry) {
            this.selectedItem = _.cloneDeep(item)
            this.entry = _.cloneDeep(entry)
            this.originalEntry = _.cloneDeep(entry)
            this.entryCreateForm = false
            this.newEntryForm = true
        },
        openAddNewEntry(item) {
            this.selectedItem = _.cloneDeep(item)
            this.entryCreateForm = true
            this.newEntryForm = true
        },
        closeAddNewEntry() {
            this.selectedItem = {}
            this.entry = {}
            this.originalEntry = {}
            this.newEntryForm = false
        },
        replaceEntry(itemId, entry) {
            const index = this.request.items.findIndex(
                item => item.id == itemId
            )
            if (index >= 0) {
                const entryIndex = this.request.items[index].entries.findIndex(
                    q => q.id == entry.id
                )
                if (entryIndex >= 0) {
                    this.request.items[index].entries.splice(entryIndex, 1, {
                        ...this.request.items[index].entries[entryIndex],
                        ...entry,
                    })
                    this.request.items.splice(index, 1, {
                        ...this.request.items[index],
                        entries: [...this.request.items[index].entries],
                    })
                }
            }
        },
        selectTextButton() {
            if (this.newRequest) {
                return 'Mark as Ready for Pricing'
            } else if (!this.request.status) {
                return 'Save'
            } else if (this.status[this.request.status].text == 'inProgress') {
                return 'Mark as Ready for Pricing'
            } else if (this.status[this.request.status].text == 'pricing') {
                return 'Send to Pre-Approval'
            } else if (this.status[this.request.status].text == 'preApproval') {
                return 'Send to Approval'
            } else if (this.status[this.request.status].text == 'approval') {
                return 'Approve for Purchase'
            } else if (this.status[this.request.status].text == 'purchasing') {
                return 'In Transit'
            } else if (this.status[this.request.status].text == 'inTransit') {
                return 'Send to Received'
            } else if (this.status[this.request.status].text == 'received') {
                return 'Close'
            } else if (this.status[this.request.status].text == 'rejected') {
                return 'Send to Pricing'
            } else {
                return null
            }
        },
        selectFunction() {
            if (this.newRequest) {
                this.readyForPricing()
            } else if (!this.request.status) {
                this.addRequest()
            } else if (
                (this.status[this.request.status].text == 'inProgress' ||
                    this.status[this.request.status].text == 'onHold') &&
                this.request.buyer
            ) {
                this.sendToPricing()
            } else if (
                this.status[this.request.status].text == 'inProgress' &&
                !this.request.buyer &&
                !this.request.readyForPricing
            ) {
                this.readyForPricing()
            } else if (this.status[this.request.status].text == 'pricing') {
                this.sendToPreApproval()
            } else if (this.status[this.request.status].text == 'preApproval') {
                this.sendToApproval()
            } else if (this.status[this.request.status].text == 'approval') {
                this.approveForPurchase()
            } else if (this.status[this.request.status].text == 'purchasing') {
                this.moveToInTransit()
            } else if (this.status[this.request.status].text == 'inTransit') {
                this.validateQuantity()
            } else if (this.status[this.request.status].text == 'received') {
                this.openRequestFormDialog()
            } else if (this.status[this.request.status].text == 'rejected') {
                this.sendToPricing()
            } else {
                return null
            }
        },
        async sendToPricing() {
            try {
                this.loading = true
                await API.updatePurchaseRequest(this.request.id, {
                    status: 'pricing',
                    notificationId: 'mqvb8rTWGR8d7Cz6nmrl',
                })
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async readyForPricing() {
            try {
                this.loading = true
                let requestId = this.newRequest
                    ? this.newRequest.id
                    : this.request.id
                await API.purchaseReadyForPricing(requestId, {
                    readyForPricing: true,
                    notificationId: 'mqvb8rTWGR8d7Cz6nmrl',
                })
                this.newRequest = undefined
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        validatePurchaseOrder() {
            let valPurchaseOrder = false
            for (const item of this.request.items) {
                if (!item.purchaseOrder || !item.purchaseOrder.orderNumber) {
                    valPurchaseOrder = true
                    break
                }
            }
            return !!valPurchaseOrder
        },
        async moveToInTransit() {
            try {
                this.loading = true
                this.requestDiff.status = 'inTransit'
                delete this.requestDiff.items
                delete this.requestDiff.totalPrice
                await API.updatePurchaseRequest(this.request.id, {
                    notificationId: 'pFDh25EcR74EMiuypq8M',
                    ...this.requestDiff,
                })
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async closePurchase() {
            try {
                this.loading = true
                this.requestDiff.status = 'closed'
                await API.closePurchaseRequest({
                    id: this.request.id,
                    notificationId: 'pFDh25EcR74EMiuypq8M',
                    ...(this.request.closingFile
                        ? { closingFile: this.request.closingFile.name }
                        : {}),
                })
                if (this.request.closingFile) {
                    await saveFile(
                        this.request.closingFile,
                        `${this.companyId}/purchases/${this.request.id}/closing`
                    )
                }
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        validateEntries() {
            let thereIsASelectedEntry = false
            if (!this.request.items) return thereIsASelectedEntry
            for (const item of this.request.items) {
                if (item.entries || item.entries?.length > 0) {
                    for (const entry of item.entries) {
                        if (!entry.sap) {
                            thereIsASelectedEntry = true
                            break
                        }
                    }
                }
                if (thereIsASelectedEntry) {
                    break
                }
            }
            return thereIsASelectedEntry
        },
        validateQuotes() {
            let thereIsASelectedQuote = false
            for (const item of this.request.items) {
                if (!item.quotes || item.quotes?.length == 0) {
                    thereIsASelectedQuote = true
                    break
                }
                if (item.quotes && !item.quotes?.find(quote => quote.able)) {
                    thereIsASelectedQuote = true
                    break
                } else {
                    thereIsASelectedQuote = false
                }
            }
            return !!thereIsASelectedQuote
        },
        async approveForPurchase() {
            try {
                this.loading = true
                this.requestDiff.status = 'purchasing'
                delete this.requestDiff.quotes
                delete this.requestDiff.purchaseOrders
                delete this.requestDiff.attachments
                delete this.requestDiff.items
                delete this.requestDiff.totalPrice
                await API.updatePurchaseRequest(this.request.id, {
                    notificationId: 'XqRU6KwrWOypCwgHQxbu',
                    ...this.requestDiff,
                })
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async validateQuantity() {
            try {
                let thereIsAnIncompleteAmount = false
                for (const item of this.request.items) {
                    if (!item.entries || item.entries?.length == 0) {
                        thereIsAnIncompleteAmount = true
                        break
                    }
                    const totalEntries = item.entries.reduce(
                        (accumulator, entry) => entry.quantity + accumulator,
                        0
                    )
                    if (item.quantity > totalEntries) {
                        thereIsAnIncompleteAmount = true
                        break
                    } else {
                        thereIsAnIncompleteAmount = false
                    }
                }
                if (thereIsAnIncompleteAmount) {
                    this.quantityWarningMessage = true
                } else {
                    await this.sendToReceived()
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async sendToReceived() {
            try {
                this.loading = true
                this.requestDiff.status = 'received'
                this.requestDiff.totalEntries = this.totalEntries
                delete this.requestDiff.items
                delete this.requestDiff.projectId
                await API.sendPurchaseToReceived(this.request.id, {
                    notificationId: 'pFDh25EcR74EMiuypq8M',
                    ...this.requestDiff,
                })
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async sendToApproval() {
            try {
                this.loading = true
                this.requestDiff.status = 'approval'
                delete this.requestDiff.quotes
                delete this.requestDiff.attachments
                delete this.requestDiff.purchaseOrders
                delete this.requestDiff.items
                delete this.requestDiff.totalPrice
                await API.updatePurchaseRequest(this.request.id, {
                    notificationId: 'CdV17CRxm8tWzZnbxWdd',
                    ...this.requestDiff,
                })
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async sendToPreApproval() {
            try {
                this.loading = true
                this.requestDiff.status = 'preApproval'
                delete this.requestDiff.attachments
                delete this.requestDiff.quotes
                delete this.requestDiff.purchaseOrders
                delete this.requestDiff.items
                delete this.requestDiff.totalPrice
                await API.updatePurchaseRequest(this.request.id, {
                    notificationId: 'su5V6D7xO01zRfzXLt7E',
                    ...this.requestDiff,
                })
                this.$emit('replaceRequest', this.requestDiff)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        uploadItemImage: async function(file, request) {
            try {
                await saveFile(
                    file,
                    `${this.companyId}/purchases/${request.id}/images`
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async addRequest() {
            try {
                this.loading = true
                this.request.projectManager = this.projectManager
                this.request.deliveryLocation = this.request.deliveryLocation
                    .text
                    ? this.request.deliveryLocation.text
                    : this.request.deliveryLocation
                if (this.request.items && this.request.items.length > 0) {
                    const items = this.request.items.map(item => {
                        return {
                            code: item.code,
                            description: item.description,
                            notes: item.notes,
                            ...(item.picture
                                ? { picture: item.picture.name }
                                : {}),
                            quantity: item.quantity,
                        }
                    })
                    this.newRequest = await API.createPurchaseRequest({
                        notificationId: 'nSl9jegzvy4rXIMIA6el',
                        ...this.request,
                        items,
                    })
                    for (const item of this.request.items) {
                        if (item.picture) {
                            await this.uploadItemImage(
                                item.picture,
                                this.newRequest
                            )
                            item.picture = item.picture.name
                        }
                    }
                } else {
                    this.newRequest = await API.createPurchaseRequest({
                        notificationId: 'nSl9jegzvy4rXIMIA6el',
                        ...this.request,
                    })
                    this.request.id = this.newRequest.id
                }
                // upload attachments
                if (
                    this.newRequest.attachments &&
                    this.newRequest.attachments.length > 0
                ) {
                    for (const file of this.newRequest.attachments) {
                        await saveFile(
                            this.localAttachments[file].file,
                            `${this.companyId}/purchases/${this.newRequest.id}/attachments`
                        )
                    }
                    this.request.id = this.newRequest.id
                    this.request.attachments = this.newRequest.attachments
                }
                if (this.newRequest && this.newRequest.id) {
                    this.requestId = this.newRequest.id
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async onCheckboxClicked(item, quote) {
            try {
                this.loading = true
                const itemIndex = this.request.items.findIndex(
                    i => i.id == item.id
                )
                if (itemIndex >= 0) {
                    if (quote.able) {
                        this.request.items.forEach(i => {
                            i.quotes = i.quotes ? i.quotes : []
                            i.quotes.forEach(q => {
                                if (q.id != quote.id && i.id == item.id) {
                                    q.able = false
                                }
                                this.request.items.splice(
                                    itemIndex,
                                    1,
                                    this.request.items[itemIndex]
                                )
                            })
                        })
                    }
                    this.$emit('replaceRequest', {
                        id: this.request.id,
                        items: this.request.items,
                    })
                }
                this.calculateTotal()
                const data = {
                    requestId: this.request.id,
                    itemId: item.id,
                    able: quote.able || true,
                }
                await API.selectQuoteToRequestsItem(quote.id, data)
                quote.able = data.able
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openAddNewQuote(item) {
            this.selectedItem = _.cloneDeep(item)
            this.createQuoteForm = true
            this.openQuoteForm = true
        },
        closeAddNewQuote() {
            this.openQuoteForm = false
            this.selectedItem = {}
            this.quote = {}
            this.originalQuote = {}
        },

        openAddNewMultipleQuote(items) {
            this.selectedItems = _.cloneDeep(items)
            this.createQuoteForm = true
            this.openMultipleQuoteForm = true
        },
        closeAddNewMultipleQuote() {
            this.openMultipleQuoteForm = false
            this.quote = {}
            this.originalQuote = {}
        },

        close() {
            this.$emit('closeDialog')
        },
        onResize() {
            const {
                title: { clientHeight: titleHeight },
            } = this.$refs
            this.height = window.innerHeight - titleHeight - 50
        },
        openAddNewItem() {
            if (this.request && this.request.id && !this.requestId) {
                this.requestId = this.request.id
            } else if (
                this.newRequest &&
                this.newRequest.id &&
                !this.requestId
            ) {
                this.requestId = this.newRequest.id
            }
            this.openItemForm = true
            this.createItem = true
        },
        closeAddNewItem() {
            this.openItemForm = false
            this.newItem = {}
            this.originalItem = {}
        },
        addItem(item) {
            if (this.request.items) {
                this.request.items.push(item)
            } else {
                this.request.items = [item]
            }
            this.request.items = [...this.request.items]
            if (this.request.id) {
                this.$emit('replaceRequest', {
                    id: this.request.id,
                    items: this.request.items,
                })
            }
            this.calculateTotal()
        },
        editItem(item) {
            this.newItem = _.cloneDeep(item)
            this.originalItem = _.cloneDeep(item)
            if (this.request && this.request.id) {
                this.requestId = this.request.id
            } else if (this.newRequest && this.newRequest.id) {
                this.requestId = this.newRequest.id
            }
            this.openItemForm = true
            this.createItem = false
            this.calculateTotal()
        },
        async replaceItem(updatedItem) {
            const index = this.request.items.findIndex(
                item => item.id == updatedItem.id
            )
            if (index >= 0) {
                this.request.items.splice(index, 1, {
                    ...this.request.items[index],
                    ...updatedItem,
                })
                if (
                    updatedItem.purchaseOrder &&
                    updatedItem.purchaseOrder.file
                ) {
                    await this.getOrderURL(this.request.items[index])
                }
                this.request.items = [...this.request.items]
                this.$emit('replaceRequest', {
                    id: this.request.id,
                    items: this.request.items,
                })
            }
            this.calculateTotal()
        },
        openDeleteQuote(material, quote) {
            this.selectedItem = _.cloneDeep(material)
            this.quote = _.cloneDeep(quote)
            this.deleteWarning = true
        },
        closeDeleteQuote() {
            this.selectedItem = {}
            this.quote = {}
            this.deleteWarning = false
        },
        async deleteQuote() {
            try {
                this.loading = true
                await this.deleteDocument()
                await API.deletePurchaseRequestQuote(
                    this.request.id,
                    this.selectedItem.id,
                    this.quote.id
                )
                this.removeQuote()
                this.closeDeleteQuote()
                this.calculateTotal()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        removeQuote() {
            const index = this.request.items.findIndex(
                item => item.id == this.selectedItem.id
            )
            if (index >= 0) {
                const quoteIndex = this.request.items[index].quotes.findIndex(
                    q => q.id == this.quote.id
                )
                if (quoteIndex >= 0) {
                    this.request.items[index].quotes.splice(quoteIndex, 1)
                    this.request.items.splice(index, 1, {
                        ...this.request.items[index],
                        quotes: [...this.request.items[index].quotes],
                    })
                    this.$emit('replaceRequest', {
                        id: this.request.id,
                        items: this.request.items,
                    })
                }
            }
        },
        async deleteDocument() {
            try {
                if (this.quote.attach) {
                    const path = `${this.companyId}/purchases/${this.request.id}/items/${this.selectedItem.id}/quotes`
                    const storageRef = storage().ref(path)
                    const docRef = storageRef.child(this.quote.attach)
                    await docRef.delete().catch(error => {
                        console.log(error.message)
                    })
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        openDeleteItem(item) {
            this.selectedItem = _.cloneDeep(item)
            this.deleteItemWarning = true
        },
        closeDeleteItem() {
            this.selectedItem = {}
            this.deleteItemWarning = false
        },
        async deleteItem() {
            try {
                this.loading = true
                if (this.request.id) {
                    await API.deletePurchaseRequestItem(
                        this.request.id,
                        this.selectedItem.id
                    )
                    if (
                        this.selectedItem.purchaseOrder &&
                        this.selectedItem.purchaseOrder.file
                    ) {
                        const path = `${this.companyId}/purchases/${this.request.id}/purchase_order`
                        const storageRef = storage().ref(path)
                        const docRef = storageRef.child(
                            this.selectedItem.purchaseOrder.file
                        )
                        await docRef.delete()
                    }
                    if (this.selectedItem.picture) {
                        const path = `${this.companyId}/purchases/${this.request.id}/images`
                        const storageRef = storage().ref(path)
                        const docRef = storageRef.child(
                            this.selectedItem.picture
                        )
                        await docRef.delete()
                    }
                    this.removeItem()
                } else {
                    this.removeItem()
                }
                this.closeDeleteItem()
                this.calculateTotal()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        removeItem() {
            const index = this.request.items.findIndex(item =>
                this.selectedItem.id
                    ? item.id == this.selectedItem.id
                    : item.description == this.selectedItem.description
            )
            if (index >= 0) {
                this.request.items.splice(index, 1)
                this.request.items = [...this.request.items]
                this.$emit('replaceRequest', {
                    id: this.request.id,
                    items: this.request.items,
                })
            }
        },
        numberFormat(amount) {
            const value = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
            }).format(Math.ceil(amount))
            return value
        },
        verifyEditableForm() {
            if (!this.createForm) {
                if (
                    (this.userId != this.request.createdBy.id ||
                        'purchasing,received,closed,approved,inTransit'.includes(
                            this.request.status
                        )) &&
                    !this.editPurchasesPermission
                ) {
                    return true
                } else if (this.editPurchasesPermission) {
                    return false
                } else {
                    return false
                }
            } else {
                return false
            }
        },
        validateTotalEntries() {
            let requestEntries = 0
            for (const item of this.request.items) {
                if (!item.entries || item.entries?.length == 0) {
                    break
                }
                requestEntries += item.entries.reduce(
                    (accumulator, entry) => entry.quantity + accumulator,
                    0
                )
            }
            this.totalEntries = requestEntries
            return requestEntries
        },
        saveButtonValidation() {
            return (
                this.request &&
                (this.request.status == 'inProgress' ||
                    this.request.status == 'onHold' ||
                    this.request.status == 'rejected' ||
                    (this.request.status != 'received' &&
                        this.request.status != 'closed' &&
                        this.editPurchasesPermission))
            )
        },
        disableSaveButton() {
            return !(
                this.originalRequest.project &&
                (this.request.type !== this.originalRequest.type ||
                    this.request.projectId !==
                        this.originalRequest.project.id ||
                    this.request.deliveryLocation !==
                        this.originalRequest.deliveryLocation ||
                    this.request.buyer !== this.originalRequest.buyer ||
                    this.request.dueDate !== this.originalRequest.dueDate ||
                    (this.originalRequest.notes &&
                        this.request.notes !== this.originalRequest.notes) ||
                    (!this.originalRequest.notes && this.request.notes))
            )
        },
        reSendEmailAlert(item) {
            this.sendWarning = true
            this.selectedQuote = _.cloneDeep(item)
        },
        closeAlert() {
            this.selectedItem = []
            this.sendWarning = false
        },
        async reSendEmail(item) {
            try {
                this.loading = true
                let params = {
                    quoteId: item.id,
                    requestId: this.request.id,
                    supplier: item.supplier,
                }
                await API.reSendPurchaseQuoteEmail(params)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.closeAlert()
            }
        },
        async updatePurchaseParams() {
            try {
                this.saveLoading = true
                if (this.request.id == undefined) {
                    return null
                }
                const paramsToUpdate = {}
                if (
                    this.request.type &&
                    this.originalRequest.type !== this.request.type
                ) {
                    paramsToUpdate.type = this.request.type
                }
                if (
                    this.request.projectId &&
                    this.originalRequest.project.id !== this.request.projectId
                ) {
                    paramsToUpdate.projectId = this.request.projectId
                }
                if (
                    this.request.deliveryLocation &&
                    this.originalRequest.deliveryLocation !==
                        this.request.deliveryLocation
                ) {
                    paramsToUpdate.deliveryLocation =
                        typeof this.request.deliveryLocation == 'object'
                            ? this.request.deliveryLocation.text
                            : this.request.deliveryLocation
                }
                if (
                    this.request.dueDate &&
                    this.originalRequest.dueDate !== this.request.dueDate
                ) {
                    paramsToUpdate.dueDate = this.request.dueDate
                }
                if (
                    this.request.buyer &&
                    this.originalRequest.buyer !== this.request.buyer
                ) {
                    paramsToUpdate.buyer = this.request.buyer
                }
                if (
                    (this.originalRequest.notes &&
                        this.request.notes !== this.originalRequest.notes) ||
                    (!this.originalRequest.notes && this.request.notes)
                ) {
                    paramsToUpdate.notes = this.request.notes
                }

                if (Object.keys(paramsToUpdate).length > 0) {
                    await API.updatePurchaseRequest(
                        this.request.id,
                        paramsToUpdate
                    )
                    if (paramsToUpdate.projectId) {
                        const index = this.projects.findIndex(
                            p => p.id == paramsToUpdate.projectId
                        )
                        if (index > -1) {
                            paramsToUpdate.project = {
                                id: this.projects[index].id,
                                name: this.projects[index].name,
                                reference: this.projects[index].reference,
                            }
                            delete paramsToUpdate.projectId
                        }
                    }

                    Object.assign(this.originalRequest, paramsToUpdate)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.saveLoading = false
            }
        },
        customFilter(item, queryText, itemText) {
            const hasValue = this.locationOptions.some(
                option => option === item
            )
            const itemNormalized = itemText.toLowerCase()
            const queryNormalized = queryText.toLowerCase()

            if (!hasValue && itemNormalized.startsWith(queryNormalized)) {
                return true
            }

            return itemNormalized.includes(queryNormalized)
        },
        validateReceive(request) {
            if (!this.receivePermission && request.status == 'inTransit') {
                return false
            } else return true
        },

        calculateTotal() {
            this.request.totalPrice = 0
            this.request.items = this.request.items ? this.request.items : []
            this.request.items.forEach(i => {
                i.quotes = i.quotes ? i.quotes : []
                i.quotes.forEach(q => {
                    if (q.able) {
                        this.request.totalPrice += i.quantity * q.price
                    }
                })
            })
        },

        disableReject() {
            if (this.request.status == 'pricing') {
                return !(this.userId == this.request.buyer)
            } else if (this.request.status == 'preApproval') {
                return !(this.userId == this.request.projectManager.id)
            } else if (
                this.request.status == 'approval' ||
                this.request.status == 'purchasing'
            ) {
                return !this.finalApprovalPermission
            } else if (this.request.status == 'inTransit') {
                return !this.inTransitPermission
            } else {
                return true
            }
        },

        disableByCreator() {
            return this.request.createdBy
                ? this.userId == this.request.createdBy.id
                    ? false
                    : true
                : false
        },

        disableByBuyer() {
            let response = true
            if (this.request.buyer) {
                response = this.userId == this.request.buyer ? false : true
                if (this.request.status == 'inTransit') {
                    response = false
                }
            }
            return response
        },
        totalQuantities(item) {
            if (item && item.entries && item.quantity) {
                let totalQuantityEntries = 0
                item.entries.forEach(entry => {
                    if (entry.quantity) {
                        totalQuantityEntries += entry.quantity
                    }
                })

                if (totalQuantityEntries >= item.quantity) {
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        },
    },
}
</script>

<style scoped>
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
.v-data-table {
    border: 1px solid #eeeeee;
}
thead {
    background: #eeeeee;
}
</style>
